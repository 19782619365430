import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Collection from 'components/Collection';
import { useIntl } from 'gatsby-plugin-react-intl';
import AuthContext from '../../provider/AuthContext';

export default function CollectionAllPage(props) {
    let intl = useIntl();
    let {
        data: {
            allShopifyProduct: { nodes: products },
            shopifyCollection: { products: colorProducts },
        },
    } = props;

    let colorHandles = colorProducts.map(p => p.handle);
    let noColorProducts = products.filter(
        p => !colorHandles.includes(p.handle)
    );

    return (
        <Layout>
            <AuthContext.Consumer>
                {auth => (
                    <Collection
                        all
                        products={noColorProducts}
                        title={intl.formatMessage({ id: 'product-all' })}
                        auth={auth}
                    />
                )}
            </AuthContext.Consumer>
        </Layout>
    );
}

export const query = graphql`
    query {
        allShopifyProduct {
            nodes {
                ...ShopifyProductFields
            }
        }
        shopifyCollection(handle: { eq: "kenra-color-all" }) {
            ...shopifyCollectionFields
        }
    }
`;
