import React from 'react'
import get from 'lodash.get'
import { useStaticQuery, graphql } from 'gatsby'
import { getCollectionUrl } from 'helpers/url'
import { useIntl } from 'gatsby-plugin-react-intl'
import { getProductUrl } from 'helpers/url'
import { mapLocale } from 'helpers/mapLocale'

import {
    Spacing,
    SharedStyles,
    Container,
    Filter,
    ProductList,
} from '../../kenra-storybook/index'
const { StPageImageTitleBar } = SharedStyles

function getCategoriesFromMenu(data, slug) {
    return data.items.map(item => {
        let collections = item.items
            .map(category => category.collection)
            .filter(Boolean)

        return {
            text: item.itemsTitle,
            isActive: collections.includes(slug),
            items: item.items
                .filter(category => {
                    let { title } = category
                    if (title === 'Kenra Color') {
                        return false
                    } else {
                        return true
                    }
                })
                .map(category => {
                    let { collection, title, href } = category
                    return {
                        text: title,
                        href: collection
                            ? getCollectionUrl(collection)
                            : href || getCollectionUrl('all'),
                        isActive: slug === collection,
                    }
                }),
        }
    })
}

function getCategoryImageUrlFromTitle(data, title) {
    let imageUrl = null
    data.items.forEach(item => {
        item.items.map(category => {
            if (category.title === title && category.imageUrl) {
                imageUrl = category.imageUrl
            }
        })
    })
    return imageUrl
}

function getHiddenCategoryImageUrlFromHandle(banners, collectionId) {
    let imageUrl = null
    let isDarkImage = false
    banners.forEach(banner => {
        if (banner.collectionId === collectionId && banner.imageUrl) {
            imageUrl = banner.imageUrl
            if (banner.isDarkBackgroundImage) {
                isDarkImage = true
            }
        }
    })
    return { imageUrl, isDarkImage }
}

function getCategoryImageDarknessFromTitle(data, title) {
    let isDarkImage = false
    data.items.forEach(item => {
        item.items.map(category => {
            if (category.title === title && category.isDarkBackgroundImage) {
                isDarkImage = category.isDarkBackgroundImage
            }
        })
    })
    return isDarkImage
}

let Collection = props => {
    let { all, products, title, slug, description, auth } = props
    let intl = useIntl()
    const {
        allContentfulMenu,
        allContentfulProduct,
        allGoogleSheetProductsRow,
    } = useStaticQuery(graphql`
        query {
            allContentfulMenu {
                nodes {
                    ...ContentfulMenuFields
                }
            }
            allContentfulProduct {
                nodes {
                    id
                    title
                    shopifyProduct {
                        handle
                    }
                    productItem {
                        title
                        images {
                            id
                            localFile {
                                publicURL
                                thumbnail: childImageSharp {
                                    resize(width: 100, height: 100) {
                                        src
                                    }
                                }
                            }
                        }
                    }
                    node_locale
                }
            }
            allGoogleSheetProductsRow {
                nodes {
                    sku
                    caAssortment
                }
            }
        }
    `)

    let localeToCheck = mapLocale(intl.locale)
    let storedRegion = ''
    if (typeof window !== 'undefined') {
        storedRegion = localStorage.getItem('preferred-region')
    }

    let contentfulMenu = allContentfulMenu.nodes.find(
        menu => menu.node_locale === localeToCheck && menu.slug === 'top'
    )

    // Get first block
    let productCategories = get(contentfulMenu, 'json.0')
    let productItems = []
    products.forEach(product => {
        let contentfulProduct = allContentfulProduct.nodes.find(
            sp =>
                sp.shopifyProduct.handle === product.handle &&
                sp.node_locale === localeToCheck
        )
        if (!contentfulProduct) {
            console.log('NO CONTENTFUL PRODUCT', product.handle)
        }

        if (storedRegion != 'CA') {
            productItems.push({
                link: getProductUrl(product.handle),
                img: get(
                    contentfulProduct,
                    'productItem.images.0.localFile.publicURL'
                ),
                title: get(contentfulProduct, 'productItem.title'),
                sku: product.variants[0].sku,
            })
        } else {
            let addProduct = false
            product.variants.forEach(variant => {
                let productInfo = allGoogleSheetProductsRow.nodes.find(
                    googleProduct =>
                        googleProduct.sku === parseInt(variant.sku) &&
                        googleProduct.caAssortment === 'Y'
                )
                if (productInfo) {
                    addProduct = true
                }
            })

            if (addProduct) {
                productItems.push({
                    link: getProductUrl(product.handle),
                    img: get(
                        contentfulProduct,
                        'productItem.images.0.localFile.publicURL'
                    ),
                    title: get(contentfulProduct, 'productItem.title'),
                    sku: product.variants[0].sku,
                })
            }
        }
    })

    let hrefAll = getCollectionUrl('all')

    let url = getCategoryImageUrlFromTitle(productCategories, title)
    let isDarkImage = getCategoryImageDarknessFromTitle(
        productCategories,
        title
    )

    if (!url) {
        const hiddenBanner = getHiddenCategoryImageUrlFromHandle(
            get(contentfulMenu, 'hiddenLandingPageBanners'),
            slug
        )
        url = hiddenBanner.imageUrl
        isDarkImage = hiddenBanner.isDarkImage
    }

    return (
        <>
            <Spacing
                removeSpaceTop
                removeSpaceTopMobile
                removeSpaceBottomMobile
            >
                <StPageImageTitleBar url={url || null}>
                    <Container useBackground style={{ maxWidth: '35%' }}>
                        <h1 style={isDarkImage ? { color: '#fff' } : {}}>
                            {title}
                        </h1>
                        {description && (
                            <p style={isDarkImage ? { color: '#fff' } : {}}>
                                {description}
                            </p>
                        )}
                    </Container>
                </StPageImageTitleBar>
            </Spacing>

            <Spacing removeSpaceTop>
                <Filter
                    items={[
                        {
                            text: intl.formatMessage({ id: 'view-all-text' }),
                            href: hrefAll,
                            isActive: all,
                        },
                        ...getCategoriesFromMenu(productCategories, slug),
                        // menuItem('Featured', featured, slug),
                        // menuItem('Category', category, slug),
                        // menuItem('Benefit', benefit, slug),
                        // {
                        //   text: 'Brand',
                        //   items: [
                        //     {
                        //       text: 'Kenra',
                        //     },
                        //     {
                        //       text: 'Kenra Platinum',
                        //     },
                        //     {
                        //       text: 'Kenra Color',
                        //       checkboxes: [
                        //         {
                        //           label: 'Permanent',
                        //           id: 'id-checkbox-permanent',
                        //         },
                        //         {
                        //           label: 'Demi-Permanent',
                        //           id: 'id-checkbox-demi-permanent',
                        //         },
                        //         {
                        //           label: 'Semi-Permanent',
                        //           id: 'id-checkbox-semi-permanent',
                        //         },
                        //         {
                        //           label: 'Simply Blonde',
                        //           id: 'id-checkbox-simply-blonde',
                        //         },
                        //         {
                        //           label: 'Studio Stylist Express',
                        //           id: 'id-checkbox-studio-stylist-express',
                        //         },
                        //       ],
                        //     },
                        //   ],
                        // },
                    ]}
                    mobileText={intl.formatMessage({ id: 'filter-text' })}
                />
            </Spacing>

            <Spacing removeSpaceTop removeSpaceBottom>
                <Container>
                    <ProductList
                        items={productItems}
                        isLoggedIn={auth.isLoggedIn}
                        addToCart={auth.addToCart}
                    />
                </Container>
            </Spacing>
        </>
    )
}

export default Collection
